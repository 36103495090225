<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.personnel") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :fields="fields"
        :items="personnel"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="lastName"
        :sortFields="sortFields"
        @row-click="
          (id) =>
            id == user.id
              ? router.push('/profile')
              : router.push(`/personnel/${id}`)
        "
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="() => router.push('/personnel/create')"
              id="header-user-create-button"
              label="create_user"
              textVariant="white"
              type="button"
              variant="indigo"
          /></span>
        </template>
        <template #cell(lastName)="{ item }">
          <div class="flex items-center">
            <img
              v-if="item.avatar"
              class="h-10 w-10 rounded-full object-cover"
              :src="getAvatar(item)"
            />
            <div
              v-else
              class="aspect-square flex h-10 w-10 items-center justify-center rounded-full bg-slate-300"
            >
              <UserIcon class="h-3/4 w-3/4 text-white" />
            </div>
            <span class="ml-5">{{ item.lastName }} {{ item.firstName }}</span>
          </div>
        </template>
        <template #cell(status)="{ value }">
          {{ t(`status.${value}`) }}
        </template>
        <template #cell(actions)="{ item }">
          <div class="flex justify-end gap-2">
            <form-button
              v-if="item.status === 'invited'"
              :command="() => cancelInvite(item.id)"
              id="header-cancel-invite-button"
              label="cancel_invite"
              textVariant="white"
              type="button"
              variant="red"
            />
          </div>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { UserIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getAvatar } from "../utils/imageUtils";
export default {
  components: { Breadcrumb, CustomTable, FormButton, UserIcon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const fields = ref([
      {
        key: "lastName",
        label: "name",
      },
      {
        key: "email",
        label: "email",
      },
      {
        key: "phone",
        label: "phone",
      },
      {
        key: "status",
        label: "status",
      },
      {
        key: "actions",
        label: "empty",
      },
    ]);

    const searchFields = ref(["email", "firstName", "lastName", "phone"]);

    const sortFields = ref(["email", "lastName"]);

    const personnel = computed(() => store.state.user.personnel);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.user.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    const cancelInvite = (userId) => {
      store.dispatch("user/cancelInvite", userId);
    };

    onMounted(() => {
      if (!personnel.value.length) {
        store.dispatch("user/getPersonnel");
      }
    });

    return {
      cancelInvite,
      fields,
      getAvatar,
      personnel,
      router,
      searchFields,
      sortFields,
      t,
      user,
      waiting,
    };
  },
};
</script>
